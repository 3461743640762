export default class Brochure {
    constructor() {
        if ($('[data-element="brochures-selection"]').length > 0) {
            import(
                '@blocks/brochure/scripts/components/brochure' /* webpackChunkName: "scripts/brochure" */
            ).then(({ default: BrochureCpnt }) => {
                BrochureCpnt.checkBrochures()
                BrochureCpnt.sendBrochuresCheck()
            })
        }
    }
}

new Brochure()
